@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&display=swap');

body {
  background-color: #282c34;
  color: white;
  margin: 0;
  font-family: 'IBM Plex Sans Thai', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}

.sidebar {
  flex: 1 0 300px;
  max-width: 400px;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.offices {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.list-container {
  flex: 1;
  overflow-y: auto;
}

.results {
  flex: 2 0 400px;
  height: 100vh;
}

.title {
  cursor: pointer;
}
.title svg {
  width: 150px;
  margin: 2rem;
}

.all-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.search-group {
  display: flex;
}

#accordion {
  margin: 0;
}

@media only screen and (max-width: 890px) {
  /* html, body {
    height: -webkit-fill-available;
  } */
  .App {
    /* display: block; */
    flex-direction: column;
    padding: 0;
  }
  .sidebar {
    width: 100%;
    max-width: unset;
    box-sizing: border-box;
    padding-bottom: 3rem;
  }
  .results {
    /* width: 100vw; */
    height: 100%;
    /* min-height: 100%; */
    margin: 0;
  }
  .title svg {
    width: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar {
    padding: 1rem;
  }
}

a {
  color: hsl(200, 90%, 80%);
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }